define("discourse/plugins/discourse-encrypt/discourse/components/modal/export-key-pair", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "discourse/lib/copy-text", "discourse-common/utils/decorators", "discourse/plugins/discourse-encrypt/lib/discourse", "discourse/plugins/discourse-encrypt/lib/pack", "discourse/plugins/discourse-encrypt/lib/protocol", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _runloop, _copyText, _decorators, _discourse, _pack, _protocol, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    {{did-insert this.export}}
    @closeModal={{@closeModal}}
    @title={{i18n "encrypt.export.title"}}
  >
    <:body>
      <p>{{i18n "encrypt.export.instructions"}}</p>
      <pre class="exported-key-pair">{{this.exported}}</pre>
    </:body>
  
    <:footer>
      <DButton
        @action={{this.copy}}
        @icon="far-clipboard"
        @label={{if
          this.copied
          "encrypt.export.copied_to_clipboard"
          "encrypt.export.copy_to_clipboard"
        }}
        @disabled={{this.inProgress}}
        class={{concat-class (if this.copied "ok") "btn btn-primary"}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "ql+IBRfV",
    "block": "[[[8,[39,0],[[4,[38,2],[[30,0,[\"export\"]]],null]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"encrypt.export.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"encrypt.export.instructions\"],null]],[13],[1,\"\\n    \"],[10,\"pre\"],[14,0,\"exported-key-pair\"],[12],[1,[30,0,[\"exported\"]]],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[16,0,[28,[37,4],[[52,[30,0,[\"copied\"]],\"ok\"],\"btn btn-primary\"],null]]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,0,[\"copy\"]],\"far-clipboard\",[52,[30,0,[\"copied\"]],\"encrypt.export.copied_to_clipboard\",\"encrypt.export.copy_to_clipboard\"],[30,0,[\"inProgress\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"d-button\",\"concat-class\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-encrypt/discourse/components/modal/export-key-pair.hbs",
    "isStrictMode": false
  });
  class ExportKeyPair extends _component2.default {
    static #_ = dt7948.g(this.prototype, "inProgress", [_tracking.tracked], function () {
      return true;
    });
    #inProgress = (dt7948.i(this, "inProgress"), void 0);
    static #_2 = dt7948.g(this.prototype, "exported", [_tracking.tracked], function () {
      return "";
    });
    #exported = (dt7948.i(this, "exported"), void 0);
    static #_3 = dt7948.g(this.prototype, "copied", [_tracking.tracked]);
    #copied = (dt7948.i(this, "copied"), void 0);
    async export() {
      try {
        const identity = await (0, _discourse.getIdentity)();
        const exported = await (0, _protocol.exportIdentity)(identity);
        this.exported = (0, _pack.packIdentity)(exported.private);
        this.inProgress = false;
      } catch {
        this.inProgress = false;
      }
    }
    static #_4 = dt7948.n(this.prototype, "export", [_decorators.bind]);
    copy() {
      const copyRange = document.querySelector("pre.exported-key-pair");
      if ((0, _copyText.default)("", copyRange)) {
        this.copied = true;
        (0, _runloop.later)(() => this.copied = false, 2000);
      }
    }
    static #_5 = dt7948.n(this.prototype, "copy", [_object.action]);
  }
  _exports.default = ExportKeyPair;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExportKeyPair);
});
define("discourse/plugins/discourse-encrypt/discourse/services/encrypt-widget-store", ["exports", "@ember/service", "@ember-compat/tracked-built-ins"], function (_exports, _service, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EncryptWidgetStore extends _service.default {
    widgets = new _trackedBuiltIns.TrackedArray();
    add(widget) {
      this.widgets.push(widget);
    }
    reset() {
      this.widgets.length = 0;
    }
  }
  _exports.default = EncryptWidgetStore;
});